.header {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: url(../../img/bg.jpg) 0 0 / cover no-repeat;
}

.headerContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.title {
  margin-bottom: 23px;
}

.list {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* 768px - 992px */
@media screen and (max-width: 992px) {
  .header {
    position: relative;
    height: 100%;
    padding: 36px 0;
  }
  .list {
    flex-wrap: wrap;
    justify-content: space-around;
  }
  .item {
    &:last-child {
      margin-top: 23px;
    }
  }
}
/* 576px - 768px */
@media screen and (max-width: 768px) {
  .list {
    flex-direction: column;
    justify-content: center;
  }
  .item {
    margin-top: 23px;

    &:first-child {
      margin-top: 0;
    }
  }
}
