@import '../../styles/utils';

.сard {
  position: relative;
  width: 320px;
  height: 509px;

  cursor: pointer;
  transition: $time;

  &:hover {
    .body {
      border: 4px solid $lightBlue;

      &::before {
        background: $lightBlue;
      }

      .kilogram {
        background: $lightBlue;
      }
    }
    .buy {
      span {
        color: #22a7e9;
      }
    }
  }

  &.selected {
    &:hover {
      .body {
        border: 4px solid $lightSelected;

        &::before {
          background: $lightSelected;
        }

        .kilogram {
          background: $lightSelected;
        }
      }
    }

    .body {
      border: 4px solid $selected;

      &::before {
        background: $selected;
      }

      .kilogram {
        background: $selected;
      }
    }
  }

  &.disabled {
    cursor: default;

    &:hover {
      .body {
        border: 4px solid #b3b3b3;
      }

      .kilogram {
        background: #b3b3b3;
      }
    }

    .body {
      border: 4px solid #b3b3b3;

      h2 {
        opacity: 0.5;
      }

      &::before {
        background: #b3b3b3;
      }

      &:after {
        opacity: 0.5;
      }
    }
    .stuffed {
      opacity: 0.5;
    }

    .kilogram {
      background: #b3b3b3;
    }
  }
}

.body {
  position: relative;
  height: 480px;
  padding: 18px 60px 0 47px;

  background: #f2f2f2;
  border: 4px solid $blue;
  border-radius: 12px;
  overflow: hidden;
  clip-path: polygon(13% 0, 100% 0, 100% 100%, 0 100%, 0 9%);
  transition: $time;

  &:before {
    content: '';
    position: absolute;
    top: -28px;
    left: 23px;
    height: 79px;
    width: 5px;

    background: $blue;
    transform: rotate(44deg);
    transition: $time;
  }

  &::after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;

    background: #b3b3b3;
    opacity: 0;
    clip-path: polygon(13% 0, 100% 0, 100% 100%, 0 100%, 0 9%);
  }
}

.topText {
  margin-bottom: 5px;
  line-height: 19px;
}

.agree {
  color: #e62e7a;
}

.title {
}

.stuffed {
  margin-bottom: 15px;
  color: #000;

  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
}

.description {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;

  p {
    display: block;
  }
}

.pcs {
  span {
    font-weight: 700;
  }
}

.bonus {
  max-width: 127px;
  span {
    font-weight: 700;
  }
}

.photo {
  position: absolute;
  bottom: -7px;
  left: -4px;
  right: 0;
  width: 282px;
  height: 272px;
}

.kilogram {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  bottom: 10px;
  right: 12px;
  width: 80px;
  height: 80px;

  background: $blue;
  border-radius: 50%;
  color: #fff;
  z-index: 1;
  transition: $time;

  span {
    display: block;
    font-size: 42px;
  }
  p {
    display: block;
    margin-bottom: -14px;

    font-size: 21px;
  }
}

.bottom {
  margin-top: 17px;
}

.buy {
  text-align: center;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;

  color: #fff;
  cursor: pointer;
  transition: $time;

  span {
    position: relative;

    color: $blue;
    transition: $time;

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 80%;
      height: 1px;

      border-bottom: 1px dashed $blue;
    }
  }
}

.disabledText {
  color: #ffff66;
}

/* 320px - 576px */
@media screen and (max-width: 576px) {
  .сard {
    width: 300px;
  }
  .body {
    padding: 18px 20px 0 47px;
    &::before {
      top: -30px;
      transform: rotate(42deg);
    }
  }
}
